import {
  Dialog,
  DialogClose,
  DialogCloseProps,
  DialogContent,
  DialogContentProps,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTitleProps,
  DialogTrigger,
} from '@radix-ui/react-dialog';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  FC,
  forwardRef,
  ReactNode,
} from 'react';
import { clsx } from 'clsx';
import {
  modalClose,
  modalContent,
  modalContentContainer,
  modalHeader,
  modalHeaderStepper,
  modalOverlay,
  modalTitle,
} from './modal.css';
import { XmarkIcon } from '../icons/react/xmark';
import { IconButton } from '../button/icon-button';

export const ModalRoot = Dialog;

export const ModalTrigger: FC<
  ComponentPropsWithoutRef<typeof DialogTrigger>
> = ({ children, ...props }) => (
  <DialogTrigger onClick={(e) => e.stopPropagation()} {...props}>
    {children}
  </DialogTrigger>
);

export const ModalContent = forwardRef<
  ElementRef<typeof DialogContent>,
  DialogContentProps & {
    contentClassName?: string;
    overlayClassName?: string;
  }
>(function ModalContentComponent(
  { children, className, contentClassName, overlayClassName, ...props },
  forwardedRef,
) {
  return (
    <DialogPortal>
      <DialogOverlay className={clsx(modalOverlay, overlayClassName)} />

      <DialogContent
        aria-describedby={undefined}
        className={clsx(modalContentContainer, className)}
        {...props}
        ref={forwardedRef}
      >
        <div className={clsx(modalContent, contentClassName)}>{children}</div>
      </DialogContent>
    </DialogPortal>
  );
});

export const ModalHeader = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & {
    withoutCloseIcon?: boolean;
    inversedClose?: boolean;
  }
>(function ModalHeaderComponent(
  { children, className, withoutCloseIcon, inversedClose },
  ref,
) {
  return (
    <div ref={ref} className={clsx(modalHeader, className)}>
      {children}
      {withoutCloseIcon ? null : (
        <ModalClose className={modalClose} asChild>
          <IconButton variant="tertiary" inversed={inversedClose}>
            <XmarkIcon />
          </IconButton>
        </ModalClose>
      )}
    </div>
  );
});

export const ModalStepper = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & {
    step: number;
    totalSteps: number;
  }
>(function ModalStepperComponent({ className, step, totalSteps }, ref) {
  return (
    <div ref={ref} className={clsx(modalHeaderStepper, className)}>
      Step {step}
      {totalSteps ? ` of ${totalSteps}` : null}
    </div>
  );
});

export const ModalClose = forwardRef<
  ElementRef<typeof DialogClose>,
  DialogCloseProps
>(function ModalCloseComponent({ children, className, ...props }, ref) {
  return (
    <DialogClose ref={ref} className={className} aria-label="Close" {...props}>
      {children}
    </DialogClose>
  );
});

export const ModalTitle = forwardRef<
  ElementRef<typeof DialogTitle>,
  DialogTitleProps & { startIcon?: ReactNode }
>(function ModalTitleComponent(
  { startIcon, children, className, ...props },
  forwardedRef,
) {
  return (
    <DialogTitle
      className={clsx(modalTitle, className)}
      {...props}
      ref={forwardedRef}
    >
      {startIcon}
      {children}
    </DialogTitle>
  );
});
